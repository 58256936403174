import { useEffect, useState } from "react";
import ProductoConjuntoDAO from "../dao/productoConjuntoDAO";
import "../styles/productosConjuntos.css";
import ModalInput from "../components/ModalInput";
import ModalMensaje from "../components/ModalMensaje";
import urlImagenes from "../dao/urlImagenes";
import Productos from "./productos";
import ProductoDAO from "../dao/productoDAO";

const ProductosConjuntos = () => {
    const [productosConjuntos, setProductosConjuntos] = useState([]);
    const [productosConjuntosMostrados, setProductosConjuntosMostrados] = useState([]);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);


    useEffect(() => {
        obtenerProductoConjuntos();
    }
        , []);

    const obtenerProductoConjuntos = async () => {
        let requestObtenerProductosConjuntos = await new ProductoConjuntoDAO().obtenerProductoConjuntos();
        if (requestObtenerProductosConjuntos.status) {
            console.log("kk", requestObtenerProductosConjuntos.data);
            setProductosConjuntos(requestObtenerProductosConjuntos.data);
            setProductosConjuntosMostrados(requestObtenerProductosConjuntos.data);
        }

    }


    const eliminarProductoConjunto =async (id) => {
        let eliminarProductoConjuntoRequest = await new ProductoConjuntoDAO().eliminarProductoConjunto(id);
        console.log(eliminarProductoConjuntoRequest.status)
        if (eliminarProductoConjuntoRequest.status) {
            notificarMensaje("Producto eliminado correctamente");
            obtenerProductoConjuntos();
        } else {
            notificarMensaje("Error al eliminar producto");
        }
    }

    const crearConjuntoProducto = () => {
        let title = "Creando conjunto producto";
        let intpus = [
            {
                name: "nombre",
                value: "",
                type: "text"
            },
            {
                name: "descripcion",
                value: "",
                type: "text"

            },
            {
                name: "imagen",
                value: "",
                type: "file"
            },
        ];
        let crearConjuntoProductoCallback = async (intpus) => {
            let nombre = intpus[0].value;
            let imagen = intpus[2].value;
            let descripcion = intpus[1].value;
            let crearProductoConjuntoRequest = await new ProductoConjuntoDAO().agregarProductoConjunto(nombre,descripcion, imagen);
            if (crearProductoConjuntoRequest.status) {
                notificarMensaje("Conjunto de productos correctamente");
                obtenerProductoConjuntos();
            } else {
                notificarMensaje("Error al registrar conjunto de productos");
            }
        }
        activarFormularioModal(crearConjuntoProductoCallback, intpus, title);
    }

    const editarProductoConjunto = (productoConjunto) => {
        let title = "Editando conjunto producto con el id " + productoConjunto.id;
        let intpus = [
            {
                name: "nombre",
                value: productoConjunto.nombre,
                type: "text"
            },
            {
                name: "descripcion",
                value: productoConjunto.descripcion,
                type: "text"

            },
            {
                name: "imagen",
                value: "",
                type: "file"
            },
        ];
        let editarProductoConjuntoCallback = async (intpus) => {
            let nombre = intpus[0].value;
            let imagen = intpus[2].value;
            let descripcion = intpus[1].value;
            let editarProductoConjuntoRequest = await new ProductoConjuntoDAO().modificarProductoConjunto(productoConjunto.id, nombre,descripcion, imagen);
            if (editarProductoConjuntoRequest.status) {
                notificarMensaje("Conjunto de productos editado correctamente");
                obtenerProductoConjuntos();
            } else {
                notificarMensaje("Error al editar conjunto de productos");
            }
        }
        activarFormularioModal(editarProductoConjuntoCallback, intpus, title);
    }

    const agregarProducto = async (idConjunto) => {
        let title = "Agregando producto al conjunto con el id " + idConjunto;
        let productos = (await new ProductoDAO().obtenerProductos()).data;
        console.log(productos);
        let intpus = [
            {
                name: "Id Producto",
                value: "",
                type: "select",
                options: productos.map(producto => {
                    return { value: producto.id, text: `nombre:${producto.nombre} id: ${producto.id} precio venta : ${producto.precioVentaDefecto}` }
                })
            },
            {
                name: "Precio venta",
                value: "",
                type: "number"
            },
        ];
        let agregarProductoCallback = async (intpus) => {
            let idProducto = intpus[0].value;
            let precioVenta = intpus[1].value;
            let agregarProductoRequest = await new ProductoConjuntoDAO().agregarProductoProductoConjunto(idConjunto, idProducto,precioVenta);
            if (agregarProductoRequest.status) {
                notificarMensaje("Producto agregado correctamente");
                obtenerProductoConjuntos();
            } else {
                notificarMensaje("Error al agregar producto");
            }
        }
        activarFormularioModal(agregarProductoCallback, intpus, title);
    }

    const eliminarProductoDelConjunto = async(idProducto,idConjunto)=>{
        let eliminarProductoDelConjuntoRequest = await new ProductoConjuntoDAO().eliminarProductoProductoConjunto(idConjunto,idProducto);
        if(eliminarProductoDelConjuntoRequest.status){
            notificarMensaje("Producto eliminado correctamente");
            obtenerProductoConjuntos();
        }else{
            notificarMensaje("Error al eliminar producto");
        }
    }

    const editarProductoDelConjunto = (idConjunto,idProducto) => {
    }

    const venderConjuntoProducto = (idConjunto) => {
        let title = "Vendiendo conjunto producto con el id " + idConjunto;
        let intpus = [
            {
                name: "fecha",
                value: "",
                type: "date"
            },
        ];
        let venderConjuntoProductoCallback = async (intpus) => {
            let fecha = intpus[0].value;
            let venderConjuntoProductoRequest = await new ProductoConjuntoDAO().venderConjuntoProducto(idConjunto, fecha);
            if (venderConjuntoProductoRequest.status) {
                notificarMensaje("Conjunto de productos vendido correctamente");
                obtenerProductoConjuntos();
            } else {
                notificarMensaje("Error al vender conjunto de productos");
            }
        }
        activarFormularioModal(venderConjuntoProductoCallback, intpus, title);
    }

    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }


    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }

    const activarFormularioModal = (confirmCallback, inputs, title) => {
        let modal = <ModalInput confirm={confirmCallback} cancel={ocultarModal} inputs={inputs} title={title}></ModalInput>
        setEstadoModal("modalActivado");
        setModalActivo(modal);

    }


    return (
        <div id="productosConjuntos">
            <div className="addContainer">
                <h2>Conjunto de productos</h2>
                <img src={`${process.env.PUBLIC_URL}/assets/icons/add.png`} className="btnAdd" onClick={() => { crearConjuntoProducto() }} title="Agregar conjunto producto"></img>
            </div>
            <div className="listaProdcutosConjunto">
                {productosConjuntosMostrados.map(productoConjunto =>
                    <div key={productoConjunto.id} className="productoConjunto">
                        <div className="productoConjuntoData">
                            <div className="informacion">
                                <p>Id conjunto producto: {productoConjunto.id}</p>
                                <p>Nombre: {productoConjunto.nombre}</p>
                                <p>Descripcion: {productoConjunto.descripcion}</p>
                                <p>Stock: {productoConjunto.stock}</p>
                                <p>Precio: {productoConjunto.productos.reduce((suma, producto) => {
                                    return suma + parseFloat(producto.precioVenta);
                                    }, 0)}</p>
                                <img src={`${urlImagenes}${productoConjunto.imagen}.${productoConjunto.extension}`}></img>
                            </div>
                            <div className="acciones">
                                <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar conjunto productos" onClick={() => { eliminarProductoConjunto(productoConjunto.id) }}></img>
                                <img src={`${process.env.PUBLIC_URL}/assets/icons/edit.gif`} title="Editar conjunto productos" onClick={() => { editarProductoConjunto(productoConjunto) }}></img>
                                <img src={`${process.env.PUBLIC_URL}/assets/icons/add.gif`} title="Agregar productos" onClick={() => { agregarProducto(productoConjunto.id) }}></img>
                                {productoConjunto.stock > 0 ? <img src={`${process.env.PUBLIC_URL}/assets/icons/sell.gif`} title="Vender productos" onClick={() => { venderConjuntoProducto(productoConjunto.id)}}></img> : ""}
                            </div>
                        </div>
                    <div className="productos">
                        <h4>Lista productos</h4>
                        <div className="listaProductos">
                            {productoConjunto.productos.map(producto =>
                                <div className="producto" key={producto.idProducto}>
                                    <div className="informacion">
                                        <p>Id Producto: {producto.idProducto}</p>
                                        <p>Código: {producto.codigo}</p>
                                        <p>Nombre: {producto.nombre}</p>
                                        <p>Precio Venta: {producto.precioVenta}</p>
                                        <img src={`${urlImagenes}${producto.idImagen}.${producto.extension}`}></img>
                                    </div>
                                    <div className="acciones">
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarProductoDelConjunto(producto.idProducto,productoConjunto.id) }}></img>
                                       
                                    </div>
                   
                                </div>
                            )}
                        </div>
                    </div>
                    </div>
                )}
            </div>
            <div className={"modal " + estadoModal}>
                {modalActivo}
            </div>
        </div >
    );
}

export default ProductosConjuntos;