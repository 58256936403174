import Origen from "./origen";

class InsumosDAO {

    async agregarInsumo(nombre, descripcion) {
        let url = Origen + "/backend/controller/insumosController.php?request=agregarInsumo";
        let formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("descrpcion", descripcion);
        
        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async eliminarInsumo(idInsumo) {
        let url = Origen + "/backend/controller/insumosController.php?request=eliminarInsumo";
        let formData = new FormData();
        formData.append("idInsumo", idInsumo);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async obtenerInsumos() {
        let url = Origen + "/backend/controller/insumosController.php?request=obtenerInsumos";
        let consulta = await fetch(url);
        return await consulta.json();
    }
}
export default InsumosDAO;