import { useEffect, useState } from "react";
import "../styles/modalComponent.css"

const ModalInput = ({ confirm, cancel, inputs, title }) => {

    useEffect(
        ()=>{
            console.log("los inputs son",inputs);
        },
        []

    );
    const [imgPreviw,setImgPreview] = useState(null);

    const cofirmarModal = (event) => {
        event.preventDefault();
        confirm(inputs);
    }

    const inputChange = (input, valor) => {
        input.value = valor;
        inputs = { ...inputs, input };
    }
    const cargarImgPreview= (archivo)=>{
        let url;
        if(archivo != null){
            url = URL.createObjectURL(archivo);
        }else{
            url =null;
        }
       
        setImgPreview(url);
    }

    const getInput = (input) => {
        switch (input.type) {
            case "text":
                return <input type={input.type} defaultValue={input.value} onChange={(event) => { inputChange(input, event.target.value) }} required></input>
            break;
            case "number":
                return <input type={input.type} defaultValue={input.value} onChange={(event) => { inputChange(input, event.target.value) }} required></input>
            break;
            case "date":
                return <input type={input.type} defaultValue={input.value} onChange={(event) => { inputChange(input, event.target.value) }} required></input>
            break;
            case "file":
                return       <><input type={input.type} onChange={(event) => { inputChange(input, event.target.files[0]);cargarImgPreview(event.target.files[0]) }} ></input>
                {imgPreviw != null ? <img src={imgPreviw} className="preview"></img> : ""} </>
            case "select":
                return <select onChange={(event) => { inputChange(input, event.target.value) }} required>
                    <option value="" >Seleccione una opción</option>
                    {input.options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
                </select>
            break;
               
        }
    }


    return (
        <div className="modalComponent">
            
            <form onSubmit={cofirmarModal}>
            <h4>{title}</h4>
                {inputs.map(input =>
                    <div key={input.name}>
                        <label>{input.name}</label>
                        {getInput(input)}
                    </div>
                )}
                <div className="acciones">
                    <input type="submit" value={"Confirmar"}></input>
                    <button type={"button"} onClick={cancel}>Cancelar</button>
                </div>
                
            </form>
        </div>
    );
}



export default ModalInput;