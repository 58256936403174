import { useEffect, useState } from "react";
import "../styles/productos.css";
import "../styles/table.css";

import ModalInput from "../components/ModalInput";
import ModalMensaje from "../components/ModalMensaje";
import InsumosDAO from "../dao/insumosDAO";
import InsumosCompradosDAO from "../dao/insumosCompradosDAO";
import esDispositivoMovil from "../dao/dispositivo";

const InsumosComprados = () => {
    const [insumosComprados, setinsumosComprados] = useState([]);
    const [insumosCompradosMostrados, setinsumosCompradosMostrados] = useState([]);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);



    useEffect(() => {
        obtenerInsumosComprados();
    }, []);

    const obtenerInsumosComprados = async () => {
        let requestInsumosComprados = await new InsumosCompradosDAO().obtenerInsumosComprados();

        if (requestInsumosComprados.status) {
            setinsumosComprados(requestInsumosComprados.data);
            setinsumosCompradosMostrados(requestInsumosComprados.data);
        }

    }

    const eliminarInsumoComprado = async (idInsumoComprado) => {
        let requestInsumosComprados = await new InsumosCompradosDAO().eliminarInsumoComprado(idInsumoComprado);

        if (requestInsumosComprados.status) {
            notificarMensaje("Insumo comprado eliminado correctamente");
            obtenerInsumosComprados();
        } else {
            notificarMensaje("No se puede eliminiar un Insumo que tenga Insumo comprados de este tipo")
        }


    }







    const activarFormularioModal = (confirmCallback, inputs, title) => {
        let modal = <ModalInput confirm={confirmCallback} cancel={ocultarModal} inputs={inputs} title={title}></ModalInput>
        setEstadoModal("modalActivado");
        setModalActivo(modal);

    }

    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }


    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }

    return (
        <div id="productos">
            <div className="addContainer">
                <h2>Insumos comprados</h2>
            </div>
            {esDispositivoMovil() ?
                <div className="contenedorInsumos">
                    <div className="listaElementos">
                        {insumosCompradosMostrados.map(insumosComprado =>
                            <div className="elemento" key={insumosComprado.idInsumoComprado}>
                                <div className="detallesPrincipales">
                                    <div className="informacion">
                                        <p>Id insumo comprado: {insumosComprado.idInsumoComprado}</p>
                                        <p>Id insumo: {insumosComprado.idInsumo}</p>
                                        <p>Nombre: {insumosComprado.nombre}</p>
                                    </div>
                                </div>
                                <div className="informacionSecundaria">
                                    <p>Fecha compra: {insumosComprado.fechaCompra}</p>
                                    <p>Precio compra: {insumosComprado.precioCompra}</p>
                                    <p>Información de la compra: {insumosComprado.informacionCompra}</p>
                                </div>
                                <div className="acciones">
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarInsumoComprado(insumosComprado.idInsumoComprado) }}></img>
                                </div>
                            </div>

                        )}

                    </div>
                </div>
                :
                <div className="tableContainer">
                    <table className="tablaProductos table">
                        <thead>
                            <tr>
                                <th>Id insumo comprado</th>
                                <th>Id insumo</th>
                                <th>Nombre</th>
                                <th>Fecha compra</th>
                                <th>Precio compra</th>
                                <th>Información compra</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {insumosCompradosMostrados.map(insumosComprado =>
                                <tr key={insumosComprado.idInsumoComprado}>
                                    <td>{insumosComprado.idInsumoComprado}</td>
                                    <td>{insumosComprado.idInsumo}</td>
                                    <td>{insumosComprado.nombre}</td>
                                    <td>{insumosComprado.fechaCompra}</td>
                                    <td>{insumosComprado.precioCompra}</td>
                                    <td>{insumosComprado.informacionCompra}</td>
                                    <td>
                                        <div className="acciones">
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarInsumoComprado(insumosComprado.idInsumoComprado) }}></img>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <div className={"modal " + estadoModal}>
                {modalActivo}
            </div>
        </div>
    );
}

export default InsumosComprados;