import { createContext, useEffect, useState } from "react";
import IniciarSesion from "../components/iniciarSesion";
import Home from "./home";
import "../styles/iframe.css";
import SesionDAO from "../dao/sesionDAO";
import esDispositivoMovil from "../dao/dispositivo";
import ModalMensaje from "../components/ModalMensaje";
import ModalInput from "../components/ModalInput";

export const SesionUsuarioContext = createContext();

export const Iframe = () => {
    const [sesionUsuario, setSesionUsuario] = useState(null);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);
    const [estadoOpcionesUser, setEstadoOpcionesUser] = useState("panelUserDesactivado");
    useEffect(() => {
        obtenerSesion();
    }, []);

    const obtenerSesion = async () => {
        let sesionRequest = await new SesionDAO().obtenerSesion();
        if (sesionRequest.status) {
            setSesionUsuario(sesionRequest.data);
        }
    }

    const cerrarSesion = async () => {
        let sesionRequest = await new SesionDAO().cerrarSesion();
        if (sesionRequest.status) {
            setSesionUsuario(null);
        }
    }

    const cambiarTelefono = async () => {
        let requestObtenerSesion =await new SesionDAO().obtenerSesion();

        let title = "Cambiar numero telefono";
        let inputs = [
            {name:"Nuevo telefono",value:requestObtenerSesion.data.telefono,type:"text"}
        ];
        let cambiarTelefonoCallback = async(inputs)=>{
            let requestCambiarTelefono = await new SesionDAO().cambiarTelefono(inputs[0].value);
            if(requestCambiarTelefono.status){
                notificarMensaje("Telefono cambiado con existo");
            }else{
                notificarMensaje("Error al cambiar telefono");
            }
        }
        activarFormularioModal(cambiarTelefonoCallback,inputs,title);
    }
    const cambiarPassword = async () => {
        let title = "Cambiar contraseña";
        let inputs = [
            {name:"Nueva contraseña",value:"",type:"text"}
        ];
        let cambiarPasswordCallback = async(inputs)=>{
            let requestCambiarPassword = await new SesionDAO().cambiarPassword(inputs[0].value);
           
            if(requestCambiarPassword.status){
                notificarMensaje("Contraseña cambiada con existo");
            }else{
                notificarMensaje("Error al cambiar contraseña");
            }
        }
        activarFormularioModal(cambiarPasswordCallback,inputs,title);
    }
    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }


    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }
    const activarFormularioModal = (confirmCallback, inputs, title) => {
        let modal = <ModalInput confirm={confirmCallback} cancel={ocultarModal} inputs={inputs} title={title}></ModalInput>
        setEstadoModal("modalActivado");
        setModalActivo(modal);

    }

    const cambiarEstadoOpionesUser= ()=>{
        if(estadoOpcionesUser == "panelUserActivado"){
            setEstadoOpcionesUser("panelUserDesactivado");
        }else{
            setEstadoOpcionesUser("panelUserActivado");
        }
    }

    return (
        <SesionUsuarioContext.Provider value={{ sesionUsuario, setSesionUsuario }}>
            <div id="Iframe">
                {sesionUsuario != null ?
                    <>
                        <img src={`${process.env.PUBLIC_URL}/assets/icons/logout.png`} onClick={() => { cerrarSesion() }} className="cerrarSesion" ></img>
                        <img src={`${process.env.PUBLIC_URL}/assets/icons/userData.png`} onClick={() => { cambiarEstadoOpionesUser() }} className="userData" ></img>
                        <div className={"modal " + estadoModal}>
                            {modalActivo}
                        </div>
                        <div className={`${estadoOpcionesUser} panelOpcionesUser`}>
                            <button onClick={() => { cambiarTelefono() }}>Cambiar telefono</button>
                            <button onClick={() => { cambiarPassword() }}>Cambiar contraseña</button>
                        </div>
                        <Home>

                        </Home>
                    </>
                    :
                    <IniciarSesion>

                    </IniciarSesion>
                }
            </div>
        </SesionUsuarioContext.Provider>
    );
}

export default Iframe;