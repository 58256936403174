import { useEffect, useState } from "react";
import "../styles/productos.css";
import "../styles/table.css";

import ModalInput from "../components/ModalInput";
import ModalMensaje from "../components/ModalMensaje";
import InsumosDAO from "../dao/insumosDAO";
import InsumosCompradosDAO from "../dao/insumosCompradosDAO";
import "../styles/table.css";
import esDispositivoMovil from "../dao/dispositivo";

const Insumos = () => {
    const [insumos, setinsumos] = useState([]);
    const [insumosMostrados, setinsumosMostrados] = useState([]);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);



    useEffect(() => {
        obtenerInsumos();
    }, []);

    const obtenerInsumos = async () => {
        let requestInsumos = await new InsumosDAO().obtenerInsumos();
        if (requestInsumos.status) {
            setinsumos(requestInsumos.data);
            setinsumosMostrados(requestInsumos.data);
        }
    }

    const eliminarInsumo = async (idInsumo) => {
        let requestInsumos = await new InsumosDAO().eliminarInsumo(idInsumo);
        if (requestInsumos.status) {
            notificarMensaje("Insumo eliminado correctamente");
            obtenerInsumos();
        } else {
            notificarMensaje("No se puede eliminiar un Insumo que tenga Insumo comprados de este tipo")
        }


    }

    const crearInsumo = () => {
        let title = "Creando insumo";
        let intpus = [
            {
                name: "nombre",
                value: "",
                type: "text"
            },
            {
                name: "descripcion",
                value: "",
                type: "text"
            },
        ];
        let crearInsumoCallback = async (intpus) => {
            let nombre = intpus[0].value;
            let descripcion = intpus[1].value;
            let requestCrearInsumo = await new InsumosDAO().agregarInsumo(nombre, descripcion);
            if (requestCrearInsumo.status) {
                notificarMensaje("Insumo registrado correctamente");
                obtenerInsumos();
            } else {
                notificarMensaje("Error al registrar insumo");
            }
        }
        activarFormularioModal(crearInsumoCallback, intpus, title);

    }

    const agregarInsumosComprados = (idInsumo) => {
        let title = `Agregando insumo comprado con le id insumo ${idInsumo}`;
        let intpus = [
            {
                name: "Fecha Compra",
                value: "",
                type: "date"
            },
            {
                name: "Precio compra",
                value: 0,
                type: "number"
            },
            {
                name: "Informacion compra",
                value: "",
                type: "text"
            }
        ];
        let crearInsumoCompradoCallback = async (intpus) => {
            let fechaCompra = intpus[0].value;
            let precioCompra = intpus[1].value;
            let InformacionCompra = intpus[2].value;
            let requestCrearInsumoComprado = await new InsumosCompradosDAO().agregarInsumoComprado(idInsumo, fechaCompra, precioCompra, InformacionCompra);
            if (requestCrearInsumoComprado.status) {
                notificarMensaje("Insumo comprado registrado correctamente");
                obtenerInsumos();
            } else {
                notificarMensaje("Error al registrar insumo comprado");
            }
        }
        activarFormularioModal(crearInsumoCompradoCallback, intpus, title);
    }



    const activarFormularioModal = (confirmCallback, inputs, title) => {
        let modal = <ModalInput confirm={confirmCallback} cancel={ocultarModal} inputs={inputs} title={title}></ModalInput>
        setEstadoModal("modalActivado");
        setModalActivo(modal);

    }

    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }


    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }

    return (
        <div id="productos">
            <div className="addContainer">
                <h2>Insumos</h2>
                <img src={`${process.env.PUBLIC_URL}/assets/icons/add.png`} className="btnAdd" onClick={() => { crearInsumo() }} title="Agregar producto"></img>
            </div>
            {esDispositivoMovil() ?
                <div className="contenedorInsumos">
                    <div className="listaElementos">
                        {insumosMostrados.map(insumo =>
                            <div className="elemento" key={insumo.idInsumo}>
                                <div className="detallesPrincipales">
                                    <div className="informacion">
                                        <p>Id: {insumo.idInsumo}</p>
                                        <p>Nombre: {insumo.nombre}</p>
                                    </div>
                                </div>
                                <div className="informacionSecundaria">
                                    <p>Descripción: {insumo.descripcion}</p>
                                </div>
                                <div className="acciones">
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarInsumo(insumo.idInsumo) }}></img>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/add.gif`} title="Agregar insumo comprado" onClick={() => { agregarInsumosComprados(insumo.idInsumo) }}></img>
                                </div>
                            </div>

                        )}

                    </div>
                </div>
                :
                <div className="tableContainer">
                    <table className="tablaProductos table">
                        <thead>
                            <tr>
                                <th>Id insumo</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {insumosMostrados.map(insumo =>
                                <tr key={insumo.idInsumo}>
                                    <td>{insumo.idInsumo}</td>
                                    <td>{insumo.nombre}</td>
                                    <td>{insumo.descripcion}</td>
                                    <td>
                                        <div className="acciones">
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarInsumo(insumo.idInsumo) }}></img>
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/add.gif`} title="Agregar insumo comprado" onClick={() => { agregarInsumosComprados(insumo.idInsumo) }}></img>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <div className={"modal " + estadoModal}>
                {modalActivo}
            </div>
        </div>
    );
}

export default Insumos;