import Origen from "./origen";

class ProductosCompradosDAO {


    async agregarProductoComprado(idProducto, color, precioCompra, informacionCompra, fechaCompra, precioVentaBase,cantidad) {
        let url = Origen + "/backend/controller/productosCompradosController.php?request=agregarProductoComprado";
        let formData = new FormData();
        formData.append("idProducto", idProducto);
        formData.append("color", color);
        formData.append("precioCompra", precioCompra);
        formData.append("informacionCompra", informacionCompra);
        formData.append("fechaCompra", fechaCompra);
        formData.append("precioVentaBase", precioVentaBase);
        formData.append("cantidad", cantidad);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        let respuesta = await consulta.json();
        
        return await respuesta;
    }

   
    async eliminarProductoComprado(idProductoComprado) {
        let url = Origen + "/backend/controller/productosCompradosController.php?request=eliminarProductoComprado";
        let formData = new FormData();
        formData.append("idProductoComprado", idProductoComprado);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    
    async obtenerProductosComprados() {
        let url = Origen + "/backend/controller/productosCompradosController.php?request=obtenerProductosComprados";
        let consulta = await fetch(url);
        return await consulta.json();
    }
}
export default ProductosCompradosDAO;