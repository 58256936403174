import Origen from "./origen";

class BajaProductoCompradoDAO{



    async registrarBajaProducto(idProductoComprado,motivo,fecha) {
        let url = Origen+"/app/backend/controller/bajaProductoCompradoController.php?request=registrarBajaProducto";
        let formData = new FormData();
        formData.append("idProductoComprado",idProductoComprado);
        formData.append("motivo",motivo);
        formData.append("fecha",fecha);
        let config = {
            method:"POST",
            body:formData
        }
        let consulta  = await fetch(url,config);
        return await consulta.json();

    }
    
     async obtenerBajasProductos() {
        let url = Origen+"/app/backend/controller/bajaProductoCompradoController.php?request=obtenerBajasProductos";
        let consulta  = await fetch(url);
        return await consulta.json();
    }
    
}

export default BajaProductoCompradoDAO;