import { useEffect } from "react";
import "../styles/form.css";
import ProductosCompradosDAO from "../dao/productosCompradosDAO";

const FormAgregarProductoComprado = ({producto,ocultarModal, obtenerProductos,notificarMensaje}) => {
    useEffect(()=>{
    },[]);


    const agregarProductoComprado = async (event)=>{
        event.preventDefault();
        let form = event.target;
        let idProducto = form.idProducto.value;
        let cantidad = form.cantidad.value;
        let precioCompra = form.precioCompra.value;
        let precioVentaBase = form.precioVentaBase.value;
        let fechaCompra = form.fechaCompra.value;
        let InformacionCompra = form.InformacionCompra.value;
        let color = form.color.value;

        let requestAgregarProductoComprado = await new ProductosCompradosDAO().agregarProductoComprado(idProducto,color,precioCompra,InformacionCompra,fechaCompra,precioVentaBase,cantidad);
        if(requestAgregarProductoComprado.status){
            obtenerProductos();
            notificarMensaje("Productos comprados agregados correctamente");
        }else{
            notificarMensaje("Error al agregar producto comprado")
        }

    }
    return (
        <form className="FormAgregarProductoComprado form" onSubmit={agregarProductoComprado}>
                <h4>Agregando productos comprados de idProducto {producto.id}</h4>

                <label>ID producto</label>
                <input  defaultValue={producto.id} readOnly name="idProducto" required></input>

                <label>Cantidad</label>
                <input defaultValue={1}  type="number" name="cantidad" required></input>

                <label>Precio compra</label>
                <input type="number" name="precioCompra" required></input>

                <label>Precio venta base</label>
                <input defaultValue={producto.precioVentaDefecto} type="number" name="precioVentaBase" required></input>

                <label>Fecha compra</label>
                <input type="date" name="fechaCompra" required></input>

                
                <label>Informacion de la compra</label>
                <textarea name="InformacionCompra"></textarea>

                <label>Color</label>
                <input type="color" name="color" required></input>

                <input type="submit" value={"Agregar productos comprados"}></input>
                <button type="button" onClick={()=>{ocultarModal()}}>Cancelar</button>


         
   

        </form>
    );
}
 
export default FormAgregarProductoComprado;