import { useState } from "react";
import ProductoDAO from "../dao/productoDAO";
import "../styles/form.css";
const FormAgregarProducto = ({obtenerProductos,ocultarModal,notificarMensaje}) => {


     const [imgPreviw,setImgPreview] = useState(null);

    const agregarProducto = async (event)=>{
        event.preventDefault();
        let form = event.target;
        let codigo = form.codigo.value;
        let nombre = form.nombre.value;
        let categoria = form.categoria.value;
        let descripcion = form.descripcion.value;
        let imagen = form.imagen.files[0];
        let precioVenta = form.precioVenta.value;
        let agregarProductoRequest  =await  new ProductoDAO().agregarProducto(codigo,categoria,nombre,descripcion,imagen,precioVenta);
      
        if(agregarProductoRequest.status){
            obtenerProductos();
            notificarMensaje("Producto agregado correctamente") 
        }else if(agregarProductoRequest.message == 1062){
            notificarMensaje("Ya existe un producto con el mismo código")
        }

       
    }

    const cargarImgPreview= (archivo)=>{
        let url;
        if(archivo != null){
            url = URL.createObjectURL(archivo);
        }else{
            url =null;
        }
       
        setImgPreview(url);
    }
    return (
        <form className="form" onSubmit={agregarProducto}>
            <h4>Agregando producto</h4>
            <label>Código</label>
            <input name="codigo"></input>
            <label>Nombre</label>
            <input name="nombre" required></input>
            <label>Categoria</label>
            <input name="categoria" required></input>
            <label>Descripción</label>
            <input name="descripcion"></input>
            <label>Imagen</label>
            <label className="inputFile" htmlFor="inputFile"><img src={`${process.env.PUBLIC_URL}/assets/icons/uploadImage.png`}></img></label>
            <input id="inputFile" type="file" name="imagen" onChange={(e)=>{cargarImgPreview(e.target.files[0])}}></input>
            {imgPreviw != null ? <img src={imgPreviw} className="preview"></img> : ""}
            <label>Precio venta</label>
            <input type="number" name="precioVenta" required></input>
            <input type="submit" value={"Agregar producto"}></input>
            <button type="button" onClick={(e)=>{ocultarModal(); e.target.parentNode.reset(); setImgPreview(null)}}>Cancelar</button>
        </form>
    );
}
 
export default FormAgregarProducto;