import Origen from "./origen";


class ProductoConjuntoDAO {

    async agregarProductoConjunto(nombre,descripcion, imagen) {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=agregarProductoConjunto";
        let formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("imagen", imagen);
        formData.append("descripcion", descripcion);

        

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async venderConjuntoProducto(idProductoConjunto, fechaVenta) {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=venderConjuntoProducto";
        let formData = new FormData();
        formData.append("idProductoConjunto", idProductoConjunto);
        formData.append("fechaVenta", fechaVenta);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();

    }


    async eliminarProductoConjunto(id) {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=eliminarProductoConjunto";
        
        let formData = new FormData();
        formData.append("id", id);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
       let respuesta = await consulta.json();
       console.log(respuesta);
        
        return respuesta;
    }

    async obtenerProductoConjuntos() {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=obtenerProductoConjunto";
        console.log(url);
        let consulta = await fetch(url);
        return await consulta.json();
    }

    async modificarProductoConjunto(id, nombre,descripcion, imagen) {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=modificarProductoConjunto";
        let formData = new FormData();
        formData.append("id", id);
        formData.append("nombre", nombre);
        formData.append("imagen", imagen);
        formData.append("descripcion", descripcion);
        console.log(descripcion)

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async agregarProductoProductoConjunto(idProductoConjunto, idProducto, precioVenta) {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=agregarProductoProductoConjunto";
        let formData = new FormData();
        formData.append("idProductoConjunto", parseInt(idProductoConjunto));
        formData.append("idProducto", parseInt(idProducto));
        formData.append("precioVenta",parseFloat(precioVenta));

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async eliminarProductoProductoConjunto(idProductoConjunto, idProducto) {
        let url = Origen + "/backend/controller/productoConjuntoController.php?request=eliminarProductoProductoConjunto";
        let formData = new FormData();
        formData.append("idProductoConjunto", idProductoConjunto);
        formData.append("idProducto", idProducto);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }
}

export default  ProductoConjuntoDAO;