import Origen from "./origen";


class ventaProductoCompradoDAO {

    async registrarVenta(idProductoComprado, fechaVenta, precioVentaFinal) {
        let url = Origen + "/backend/controller/ventaProductoCompradoController.php?request=registrarVenta";
        let formData = new FormData();
        formData.append('idProductoComprado', idProductoComprado);
        formData.append('fechaVenta', fechaVenta);
        formData.append('precioVentaFina', precioVentaFinal);
        
        let response = await fetch(url, {
            method: 'POST',
            body: formData
        });

        return await response.json();
    }

    async obtenerProductosVendidos() {
        let url = Origen + "/backend/controller/ventaProductoCompradoController.php?request=obtenerProductosVendidos";
        let response = await fetch(url);
        return await response.json();
    }
    async obtenerDatosVentasProductosVendidos(desde,hasta){
        let url = Origen + "/backend/controller/ventaProductoCompradoController.php?request=obtenerDatosVentasProductosVendidos";
        let formData = new FormData();
        formData.append('desde', desde);
        formData.append('hasta', hasta);
        
        let response = await fetch(url, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }

    async obtenerDetalleGastoProductosDescartados(desde, hasta){
        let url = Origen + "/backend/controller/ventaProductoCompradoController.php?request=obtenerDetalleGastoProductosDescartados";
        let formData = new FormData();
        formData.append('desde', desde);
        formData.append('hasta', hasta);
        
        let response = await fetch(url, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }
}
export default ventaProductoCompradoDAO;