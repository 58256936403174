
import { useContext, useState } from "react";
import SesionDAO from "../dao/sesionDAO";
import "../styles/formularioInicarSesion.css";
import { SesionUsuarioContext } from "../windows/Iframe";
import ModalMensaje from "./ModalMensaje";

const IniciarSesion = () => {
    const { sesion, setSesionUsuario } = useContext(SesionUsuarioContext);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);

    const iniciarSesion = async (e) => {

        e.preventDefault();
        let usuario = e.target.usuario.value;
        let password = e.target.password.value;
        let sesionRequest = await new SesionDAO().iniciarSesion(usuario, password);
        if (sesionRequest.status) {
            setSesionUsuario(sesionRequest.data);
        } else {
            notificarMensaje("Datos incorrectos");
        }

    }
    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }

    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }
    return (
        <>
            <form className="formularioInicarSesion" onSubmit={iniciarSesion}>
                <label>Usuario</label>
                <input name="usuario" type="texto"></input>
                <label>Contraseña</label>
                <input name="password" type="password"></input>
                <input type="submit" value={"Iniciar sesion"}></input>
            </form>
            <div className={"modal " + estadoModal}>
                {modalActivo}
            </div>
        </>
    );

}

export default IniciarSesion;