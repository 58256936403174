import { useEffect, useState } from "react";
import ProductoDAO from "../dao/productoDAO";
import "../styles/form.css";
import urlImagenes from "../dao/urlImagenes";
const FormEditarProducto = ({obtenerProductos,producto,ocultarModal,notificarMensaje}) => {

    const [imgPreviw,setImgPreview] = useState(null);

    useEffect(()=>{
        setImgPreview(`${urlImagenes}${producto.idImagen}.${producto.extensionImagen}`);
    },[]);

    const editarProducto = async (event)=>{
        event.preventDefault();
        let form = event.target;
        let codigo = form.codigo.value;
        let nombre = form.nombre.value;
        let categoria = form.categoria.value;
        let descripcion = form.descripcion.value;
        let imagen = form.imagen.files[0];
        let agregarProductoRequest  =await  new ProductoDAO().modificarProducto(producto.id,codigo,categoria,nombre,descripcion,imagen);
       
        if(agregarProductoRequest.status){
            obtenerProductos();
            notificarMensaje("Producto editado correctamente");

        }else if(agregarProductoRequest.message == 1062){
            notificarMensaje("Ya existe un producto con el mismo código");
        }
      
       
    }

    const cargarImgPreview= (archivo)=>{
        let url;
        if(archivo != null){
            url = URL.createObjectURL(archivo);
        }else{
            url =null;
        }
       
        setImgPreview(url);
    }
    return (
        <form className="form" onSubmit={editarProducto}>
            <h4>Editando producto</h4>
            <label>Código</label>
            <input name="codigo" defaultValue={producto.codigo} ></input>
            <label>Nombre</label>
            <input name="nombre" defaultValue={producto.nombre} required></input>
            <label>Categoria</label>
            <input name="categoria" defaultValue={producto.categoria} required></input>
            <label>Descripción</label>
            <input name="descripcion" defaultValue={producto.descripcion} ></input>
            <label>Imagen</label>
            <label className="inputFile" htmlFor="inputFile"><img src={`${process.env.PUBLIC_URL}/assets/icons/uploadImage.png`}></img></label>
            <input id="inputFile" type="file" name="imagen" onChange={(e)=>{cargarImgPreview(e.target.files[0])}} ></input>
            {imgPreviw != null ? <img src={imgPreviw} className="preview"></img> : ""}
            <input type="submit" value={"Editar producto"}></input>
            <button type="button" onClick={()=>{ocultarModal()}}>Cancelar</button>
        </form>
    );
}
 
export default FormEditarProducto;