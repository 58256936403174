const VentaProductosComprados = () => {
    return (
        <div id="VentaProductosComprados">
            <form className="form">
                <h4>Venta producto</h4>
                
            </form>
        </div>
    );
}
 
export default VentaProductosComprados;