import { useEffect, useState } from "react";
import ventaProductoCompradoDAO from "../dao/ventaProductoCompradoDAO";
import "../styles/ganancia.css";
import InsumosCompradosDAO from "../dao/insumosCompradosDAO";
import esDispositivoMovil from "../dao/dispositivo";
const Ganancias = () => {

    const [desde, setDesde] = useState();
    const [hasta, setHasta] = useState();

    const [insumosComprados, setInsumosComprados] = useState([]);
    const [productosVendidos, setProductosVendidos] = useState([]);
    const [productosDescartados, setProductosDescartados] = useState([]);
    const [gananciaTotalProductos, setGananciaTotalProductos] = useState(0);
    const [costeTotalInsumos, setCosteTotalInsumos] = useState(0);
    const [costeTotalProductosDescartados, setCosteTotalProductosDescartados] = useState(0);
    const [gananciaTotal, setGananciaTotal] = useState(0);
    const [costeProductosComprados, setCosteProductosComprados] = useState(0);
    const [gananciaProductosVendidos, setGananciaProductosVendidos] = useState(0);


    useEffect(() => {
        setGananciaTotal(gananciaTotalProductos - costeTotalInsumos - costeTotalProductosDescartados);
    }, [costeTotalInsumos, costeTotalProductosDescartados, gananciaTotalProductos]);




    const generarInforme = () => {
        obtenerVentaProductosComprados();
        obtenerInsumosComprados();
        obtenerDetalleGastoProductosDescartados();
    }

    const obtenerVentaProductosComprados = async () => {
        let requestObtenerDatosProductosVendidos = await new ventaProductoCompradoDAO().obtenerDatosVentasProductosVendidos(desde, hasta);
        if (requestObtenerDatosProductosVendidos.status) {
            setProductosVendidos(requestObtenerDatosProductosVendidos.data);
            let productosVendido = requestObtenerDatosProductosVendidos.data;
            let gananciaProductosVendidos = 0;
            let costeProductosComprados = 0;
            let total = productosVendido.reduce((suma, productoVendido) => {
                gananciaProductosVendidos = gananciaProductosVendidos + parseFloat(productoVendido.precioVentaFinal);
                costeProductosComprados = costeProductosComprados + parseFloat(productoVendido.precioCompra);
                return suma + parseFloat(productoVendido.precioVentaFinal) - parseFloat(productoVendido.precioCompra);
            }, 0);
            setGananciaProductosVendidos(gananciaProductosVendidos);
            setCosteProductosComprados(costeProductosComprados);
            setGananciaTotalProductos(total);
        }
    }

    const obtenerInsumosComprados = async () => {
        let requestObtenerInsumosComprados = await new InsumosCompradosDAO().obtenerInsumosCompradosFecha(desde, hasta);
        if (requestObtenerInsumosComprados.status) {
            setInsumosComprados(requestObtenerInsumosComprados.data);
            let insumosComprados = requestObtenerInsumosComprados.data;
            let total = insumosComprados.reduce((suma, insumoComprados) => {
                return suma + parseFloat(insumoComprados.precioCompra);
            }, 0);
            setCosteTotalInsumos(total);
        }
    }

    const obtenerDetalleGastoProductosDescartados = async () => {
        let requestObtenerDetalleGastoProductosDescartados = await new ventaProductoCompradoDAO().obtenerDetalleGastoProductosDescartados(desde, hasta);
        if (requestObtenerDetalleGastoProductosDescartados.status) {
            setProductosDescartados(requestObtenerDetalleGastoProductosDescartados.data);
            let productosDescartados = requestObtenerDetalleGastoProductosDescartados.data;
            let total = productosDescartados.reduce((suma, productoDescartados) => {

                return suma + parseFloat(productoDescartados.precioCompra);
            }, 0);
            setCosteTotalProductosDescartados(total);
        }
    }


    return (
        <div id="Ganancias">
            <div className="panelFechas">
                <div >
                    <p>Desde</p>
                    <input type="date" onChange={(e) => { setDesde(e.target.value) }}></input>
                </div>
                <div>
                    <p>Hasta</p>
                    <input type="date" onChange={(e) => { setHasta(e.target.value) }}></input>
                </div>
                <button onClick={generarInforme}>Generar informe</button>
            </div>
            <div className="gananciaTotal">
                <h3>Ganancias</h3>
                <p>Ganancia en venta productos</p> <p className="ganancia"> {gananciaProductosVendidos}</p>
                <p>Coste productos comprados :</p><p className="coste"> {costeProductosComprados}</p>
                <p>Ganancia total en productos:</p><p className="ganancia">{gananciaTotalProductos}</p>
                <p>Coste en insumos:</p><p className="coste"> {costeTotalInsumos}</p>
                <p>Coste en productos dado de baja:</p><p className="coste"> {costeTotalProductosDescartados}</p>
                <p>Ganancia Total:</p><p className="ganancia"> {gananciaTotal}</p>
            </div>
            <h3>Detalles</h3>
            <h4>Productos vendidos</h4>
            <div className="datosVentaProductos">
                {esDispositivoMovil() ?
                    <div className="contenedorInsumos">
                        <div className="listaElementos">
                            {productosVendidos.map(productoVendido =>
                                <div className="elemento" key={productoVendido.idProductoComprado}>
                                    <div className="detallesPrincipales all">
                                        <div className="informacion">
                                            <h4>Información de id producto comprado {productoVendido.idProducto}</h4>
                                            <p className="ganancia">Ganancia {parseFloat(productoVendido.precioVentaFinal) - parseFloat(productoVendido.precioCompra)}</p>
                                            <h4>Información extra</h4>
                                            <p>Nombre producto {productoVendido.nombreProducto}</p>
                                            <p>Precio compra {productoVendido.precioCompra}</p>
                                            <p>Precio venta {productoVendido.precioVentaFinal}</p>
                                            <p>Id producto: {productoVendido.idProducto}</p>
                                            <p>Id producto comprado: {productoVendido.idProductoComprado}</p>
                                            <p>Fecha compra: {productoVendido.fechaCompra}</p>
                                            <p>Fecha venta: {productoVendido.fechaVenta}</p>
                                        </div>
                                    </div>
                                    <div className="none">
                                    </div>
                                </div>

                            )}

                        </div>
                    </div>
                    :
                    <table >
                        <thead>
                            <tr>
                                <th>Id producto</th>
                                <th>Id producto comprado</th>
                                <th>Nombre producto</th>
                                <th>Fecha compra</th>
                                <th>Fecha venta</th>
                                <th>Precio compra</th>
                                <th>Precio venta</th>
                                <th>Ganancia</th>
                            </tr>
                        </thead>

                        <tbody>
                            {productosVendidos.map(productoVendido =>
                                <tr key={productoVendido.idProductoComprado}>
                                    <td>{productoVendido.idProducto}</td>
                                    <td>{productoVendido.idProductoComprado}</td>
                                    <td>{productoVendido.nombreProducto}</td>
                                    <td>{productoVendido.fechaCompra}</td>
                                    <td>{productoVendido.fechaVenta}</td>
                                    <td>{productoVendido.precioCompra}</td>
                                    <td>{productoVendido.precioVentaFinal}</td>
                                    <td>{parseFloat(productoVendido.precioVentaFinal) - parseFloat(productoVendido.precioCompra)}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Ganancia total en productos</td>
                                <td className="ganancia">{gananciaTotalProductos} </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
            <h4>Insumos comprados</h4>
            <div className="datosVentaInsumos">
                {esDispositivoMovil() ?
                    <div className="contenedorInsumos">
                        <div className="listaElementos">
                            {insumosComprados.map(insumoComprados =>
                                <div className="elemento" key={insumoComprados.idInsumoComprado}>
                                    <div className="detallesPrincipales all">
                                        <div className="informacion">
                                            <h4>Información de id insumo comprado {insumoComprados.idInsumoComprado}</h4>
                                            <p className="coste">Precio compra: {insumoComprados.precioCompra}</p>
                                            <h4>Información extra</h4>
                                            <p>Nombre insumo {insumoComprados.nombre}</p>
                                            <p>Fecha compra {insumoComprados.fechaCompra}</p>
                                            <p>Información compra {insumoComprados.informacionCompra}</p>
                                        </div>
                                    </div>
                                    <div className="none">
                                    </div>
                                </div>

                            )}

                        </div>
                    </div>
                    :
                    <table >
                        <thead>
                            <tr>
                                <th>Id insumo comprado</th>
                                <th>Nombre insumo</th>
                                <th>Fecha compra</th>
                                <th>Información compra</th>
                                <th>Precio compra</th>
                            </tr>
                        </thead>

                        <tbody>
                            {insumosComprados.map(insumoComprados =>
                                <tr key={insumoComprados.idInsumoComprado}>
                                    <td>{insumoComprados.idInsumoComprado}</td>
                                    <td>{insumoComprados.nombre}</td>
                                    <td>{insumoComprados.fechaCompra}</td>
                                    <td>{insumoComprados.informacionCompra}</td>
                                    <td>{insumoComprados.precioCompra}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Gasto total en insumos</td>
                                <td className="coste">{costeTotalInsumos} </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>

            <h4>Productos descartados</h4>
            <div className="datosVentaProductos">
                {esDispositivoMovil() ?
                    <div className="contenedorInsumos">
                        <div className="listaElementos">
                            {productosDescartados.map(productoDescartados =>
                                <div className="elemento" key={productoDescartados.idProductoComprado}>
                                    <div className="detallesPrincipales all">
                                        <div className="informacion">
                                            <h4>Información del id producto comprado {productoDescartados.idProductoComprado}</h4>
                                            <p className="coste">Precio compra: {productoDescartados.precioCompra}</p>
                                            <h4>Información extra</h4>
                                            <p>Nombre producto {productoDescartados.nombre}</p>
                                            <p>Fecha Baja {productoDescartados.fechaBaja}</p>
                                            <p>Motivo baja {productoDescartados.motivoBaja}</p>
                                        </div>
                                    </div>
                                    <div className="none">
                                    </div>
                                </div>

                            )}

                        </div>
                    </div>
                    :
                    <table>
                        <thead>
                            <tr>
                                <th>Id producto comprado</th>
                                <th>Nombre producto</th>
                                <th>Fecha baja</th>
                                <th>motivo baja</th>
                                <th>Precio compra</th>
                            </tr>
                        </thead>

                        <tbody>
                            {productosDescartados.map(productoDescartados =>
                                <tr key={productoDescartados.idProductoComprado}>
                                    <td>{productoDescartados.idProductoComprado}</td>
                                    <td>{productoDescartados.nombre}</td>
                                    <td>{productoDescartados.fechaBaja}</td>
                                    <td>{productoDescartados.motivoBaja}</td>
                                    <td>{productoDescartados.precioCompra}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Gasto total en productos descartados</td>
                                <td className="coste"> {costeTotalProductosDescartados} </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>

        </div>
    );
}

export default Ganancias;