import React, { useEffect, useState } from 'react';
import ProductoDAO from '../dao/productoDAO';
import ProductosComprados from './productosComprados';
import ProductosCompradosDAO from '../dao/productosCompradosDAO';
import "../styles/detalleInventarioDisponible.css"
const DetalleInventarioDisponible = () => {
    const [costeTotal,setCosteTotal]=useState(0);
    const [precioVentaTotal,setPrecioVentaTotal]=useState(0);
    const [gananciaTotal,setGananciaTotal]=useState(0);

    useEffect(() => {

        obtenerProductosDisponibles();
    }, []);

    useEffect(() => {
        let total = precioVentaTotal - costeTotal;
        setGananciaTotal(total);
    },[costeTotal,precioVentaTotal]);

    const obtenerProductosDisponibles = async () => {
       let requestProductos = await new ProductosCompradosDAO().obtenerProductosComprados();
       if(requestProductos.status){
         let productoCompradoDisponible = requestProductos.data.filter(productoComprado => productoComprado.estado =="Disponible");
         let coste = productoCompradoDisponible.reduce((suma, productoComprado) => {
            return suma + parseFloat(productoComprado.precioCompra);
        }, 0);
        setCosteTotal(coste);
        let totalVenta = productoCompradoDisponible.reduce((suma, productoComprado) => {
            return suma + parseFloat(productoComprado.precioVentaBase);
        }, 0);
        setPrecioVentaTotal(totalVenta);

       }
    }
    return (  
        <div id="DetalleInventarioDisponible">
            <table>
                <thead>
                    <tr>
                        <th>Coste compra total</th>
                        <th>Ingreso venta total</th>
                        <th>Ganancia total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='coste'>{costeTotal}</td>
                        <td className='ganancia'>{precioVentaTotal}</td>
                        <td className='ganancia'>{gananciaTotal}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
 
export default DetalleInventarioDisponible;