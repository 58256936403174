import "../styles/modalMensaje.css";

const ModalMensaje = ({mensaje,ocultarModal}) => {
    return (
        <div className="modalMensaje">
            <p>{mensaje}</p>
            <button onClick={ocultarModal}><img src={`${process.env.PUBLIC_URL}/assets/icons/okay.gif`} onClick={ocultarModal}></img></button>
        </div>
    );
}
 
export default ModalMensaje;