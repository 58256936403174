import Origen from "./origen";

class ProductoDAO {

    
    async agregarProducto(codigo, categoria, nombre, descripcion, imagen,precioVenta) {
        let url = Origen + "/backend/controller/productoController.php?request=agregarProducto";
        let formData = new FormData();
        
        formData.append("codigo", codigo);

        formData.append("categoria", categoria);
        formData.append("nombre", nombre);
        formData.append("descripcion", descripcion);
        formData.append("imagen", imagen);
        formData.append("precioVenta", precioVenta);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        let respuesta = await consulta.json();
       
        return await respuesta;
    }

   
    async eliminarProducto(idProducto) {
        let url = Origen + "/backend/controller/productoController.php?request=eliminarProducto";
        let formData = new FormData();
        formData.append("idProducto", idProducto);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        let respuesta = await consulta.json();
       
        return await respuesta;
    }

   
    async modificarProducto(idProducto, codigo, categoria, nombre, descripcion, imagen) {
        let url = Origen + "/backend/controller/productoController.php?request=modificarProducto";
        let formData = new FormData();
       
        formData.append("idProducto", idProducto);
        formData.append("codigo", codigo);
        formData.append("categoria", categoria);
        formData.append("nombre", nombre);
        formData.append("descripcion", descripcion);
        if (imagen) {
            formData.append("imagen", imagen);
        }

        let config = {
            method: "POST",
            body: formData
        };

      
        let consulta = await fetch(url, config);
        let respuesta = await consulta.json();
       
        return await respuesta;
    }

    async cambiarPrecioVentaBase(idProducto,precioVentaBase){
        let url = Origen + "/backend/controller/productosCompradosController.php?request=cambiarPrecioVentaBase";
        let formData = new FormData();
        formData.append("idProducto", idProducto);
        formData.append("precioVentaBase",precioVentaBase);
        let config = {
            method: "POST",
            body: formData
        };
        let consulta = await fetch(url, config);
        let respuesta = await consulta.json();
        return await respuesta;;
    }

   
    async obtenerProductos() {
        let url = Origen + "/backend/controller/productoController.php?request=obtenerProductos";
        let consulta = await fetch(url);
        return await consulta.json();
    }
}

export default ProductoDAO;