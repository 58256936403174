import { useEffect, useState } from "react";
import ProductoDAO from "../dao/productoDAO";
import Origen from "../dao/origen";
import urlImagenes from "../dao/urlImagenes";
import "../styles/productos.css";
import "../styles/table.css";
import FormAgregarProducto from "../components/FormAgregarProducto";
import FormEditarProducto from "../components/FormEditarProducto";
import FormAgregarProductoComprado from "../components/FormAgregarProductoComprado";
import ModalInput from "../components/ModalInput";
import ModalMensaje from "../components/ModalMensaje";
import esDispositivoMovil from "../dao/dispositivo";

const Productos = () => {
    const [productos, setproductos] = useState([]);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);


    useEffect(() => {
        obtenerProductos();
    }, []);

    const obtenerProductos = async () => {
        let requestProductos = await new ProductoDAO().obtenerProductos();
        if (requestProductos.status) {
            setproductos(requestProductos.data);
        }else{
           
        }
       
    }

    const eliminarProducto = async (idProducto) => {
        let requestProductos = await new ProductoDAO().eliminarProducto(idProducto);
        if (requestProductos.status) {
            notificarMensaje("Producto eliminado correctamente");
            obtenerProductos();
        } else {
            notificarMensaje("No se puede eliminiar un producto que tenga productos comprados de este tipo")
        }



    }

    const mostrarFormularioCrear = () => {
        setEstadoModal("modalActivado");
        setModalActivo(null);
        setTimeout(()=>{
            setModalActivo(<FormAgregarProducto ocultarModal={ocultarModal} obtenerProductos={obtenerProductos} notificarMensaje={notificarMensaje}></FormAgregarProducto>);
        },100);
        
    }

    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }

    const mostrarFormularioEditar = (producto) => {
        setEstadoModal("modalActivado");
        setModalActivo(null);
       
        setTimeout(()=>{
            setModalActivo(<FormEditarProducto ocultarModal={ocultarModal} obtenerProductos={obtenerProductos} producto={producto} notificarMensaje={notificarMensaje}></FormEditarProducto>);
        },100);
       
    }

    const cambiarPrecioVentaBase = async (producto) => {

        const confirm = async (inputs) => {
            let requestCambiarPrecioVentaBase = await new ProductoDAO().cambiarPrecioVentaBase(producto.id, parseInt(inputs[0].value));

            if (requestCambiarPrecioVentaBase.status) {
                notificarMensaje("Precio de venta base cambiado correctamente");
                obtenerProductos();
            } else {
                notificarMensaje("Error al cambiar precio de venta base");
            }
        }
        let modal = <ModalInput confirm={confirm} cancel={ocultarModal} inputs={[{ name: "Nuevo precio", value: producto.precioVentaDefecto, type: "number" }]} title={`Cambiar precio a los productos con el id ${producto.id}`}></ModalInput>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }


    const mostrarAgregarProductoComprado = async (producto) => {
        setEstadoModal("modalActivado");
        setModalActivo(<FormAgregarProductoComprado obtenerProductos={obtenerProductos} ocultarModal={ocultarModal} producto={producto} notificarMensaje={notificarMensaje}></FormAgregarProductoComprado>);
    }

    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }

    return (
        <div id="productos">
            <div className="addContainer">
                <h2>Productos</h2>
                <img src={`${process.env.PUBLIC_URL}/assets/icons/add.png`} className="btnAdd" onClick={() => { mostrarFormularioCrear() }} title="Agregar producto"></img>
            </div>
            {esDispositivoMovil() ?
                <div className="contenedorProductos">
                    <div className="listaElementos">
                        {productos.map(producto =>
                            <div className="elemento"  key={producto.id}>
                                <div className="detallesPrincipales">
                                    <img src={`${urlImagenes}${producto.idImagen}.${producto.extensionImagen}`}></img>
                                    <div className="informacion">
                                        <p>Id: {producto.id}</p>
                                        <p>Código: {producto.codigo}</p>
                                        <p>Nombre: {producto.nombre}</p>
                                        <p>Stock: {producto.stock}</p>
                                    </div>
                                </div>
                                <div className="informacionSecundaria">
                                    <p>Precio venta: {producto.precioVentaDefecto}</p>
                                    <p>Cateogria: {producto.categoria}</p>
                                    <p>Descripción: {producto.descripcion}</p>
                                </div>
                                <div className="acciones">
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/plata.gif`} title="Cambiar precio a los productos de este tipo" onClick={() => { cambiarPrecioVentaBase(producto) }}></img>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/edit.gif`} title="Modificar producto" onClick={() => { mostrarFormularioEditar(producto) }}></img>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarProducto(producto.id) }}></img>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/add.gif`} title="Agregar producto comprado" onClick={() => { mostrarAgregarProductoComprado(producto) }}></img>
                                </div>
                            </div>

                        )}

                    </div>
                </div> :

                <div className="tableContainer">
                    <table className="tablaProductos table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Categoria</th>
                                <th>Precio venta</th>
                                <th>Imagen</th>
                                <th>Descripción</th>
                                <th>Stock</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productos.map(producto =>
                                <tr key={producto.id}>
                                    <td>{producto.id}</td>
                                    <td>{producto.codigo}</td>
                                    <td>{producto.nombre}</td>
                                    <td>{producto.categoria}</td>
                                    <td>{producto.precioVentaDefecto}</td>
                                    <td><img src={`${urlImagenes}${producto.idImagen}.${producto.extensionImagen}`}></img></td>
                                    <td>{producto.descripcion}</td>
                                    <td>{producto.stock}</td>
                                    <td>
                                        <div className="acciones">
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/plata.gif`} title="Cambiar precio a los productos de este tipo" onClick={() => { cambiarPrecioVentaBase(producto) }}></img>
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/edit.gif`} title="Modificar producto" onClick={() => { mostrarFormularioEditar(producto) }}></img>
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto" onClick={() => { eliminarProducto(producto.id) }}></img>
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/add.gif`} title="Agregar producto comprado" onClick={() => { mostrarAgregarProductoComprado(producto) }}></img>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <div className={"modal " + estadoModal}>
                {modalActivo}
            </div>
        </div>
    );
}

export default Productos;