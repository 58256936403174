import Origen from "./origen";

class InsumosCompradosDAO {

    async agregarInsumoComprado(idInsumo, fechaCompra, precioCompra, InformacionCompra) {
        let url = Origen+ "/app/backend/controller/insumosCompradosController.php?request=agregarInsumoComprado";
        let formData = new FormData();
        formData.append("idInsumo", parseInt(idInsumo));
        formData.append("fechaCompra", fechaCompra);
        formData.append("precioCompra", parseInt(precioCompra));
        formData.append("InformacionCompra", InformacionCompra);
        
        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async eliminarInsumoComprado(idInsumoComprado) {
        let url = Origen + "/backend/controller/insumosCompradosController.php?request=eliminarInsumoComprado";
        let formData = new FormData();
        formData.append("idInsumo", idInsumoComprado);

        let config = {
            method: "POST",
            body: formData
        };

        let consulta = await fetch(url, config);
        return await consulta.json();
    }

    async obtenerInsumosComprados() {
        let url = Origen + "/backend/controller/insumosCompradosController.php?request=obtenerInsumosComprados";
        let consulta = await fetch(url);
        return await consulta.json();
    }

    async obtenerInsumosCompradosFecha(desde,hasta){
        let url = Origen + "/backend/controller/insumosCompradosController.php?request=obtenerInsumosCompradosFecha";
        let formData = new FormData();
        formData.append('desde', desde);
        formData.append('hasta', hasta);
        
        let response = await fetch(url, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }
}

export default InsumosCompradosDAO;