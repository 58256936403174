import ventaProductoCompradoDAO from "../dao/ventaProductoCompradoDAO";
import "../styles/form.css";
const FormVenderProductoComprado = ({productoComprado,ocultarFormulario,obtenerProductosComprados,notificarMensaje}) => {

    const venderProducto =async (event)=>{
        event.preventDefault();
        let form = event.target;
        let idProductoComprado= form.idProductoComprado.value;
        let fechaVenta= form.fechaVenta.value;
        let precioVentaFinal= form.precioVentaFinal.value;
        let requestVentaProducto = await new ventaProductoCompradoDAO().registrarVenta(idProductoComprado,fechaVenta,precioVentaFinal);
        if(requestVentaProducto.status){
            ocultarFormulario();
            obtenerProductosComprados();
            notificarMensaje("Producto vendido con exito");
        }else{
            ocultarFormulario();
            notificarMensaje("Error al vender producto");
        }
    }
    return (
        <form className="form" onSubmit={venderProducto}>
        <h4>Vendiendo producto comprado con el id {productoComprado.idProductoComprado}</h4>

        <label>Id producto comprado en venta</label>
        <input defaultValue={productoComprado.idProductoComprado} readOnly name="idProductoComprado" required></input>

        <label>Fecha de venta</label>
        <input type="date" name="fechaVenta" required></input>

        <label>Precio de venta final</label>
        <input defaultValue={productoComprado.precioVentaBase} name="precioVentaFinal" required></input>

       
       
        <input type="submit" value={"Vender producto"}></input>
        <button type="button" onClick={()=>{ocultarFormulario()}}>Cancelar</button>
    </form>
    );
}
 
export default FormVenderProductoComprado;