import Origen from "./origen";

class SesionDAO {

    
    async obtenerSesion() {
        let url = Origen + "/backend/controller/sesionController.php?request=obtenerSesion";
        let response = await fetch(url);
        let sesion = await response.json();
       
        return await sesion;
    }

    
    async cerrarSesion() {
        let url = Origen + "/backend/controller/sesionController.php?request=cerrarSesion";
        let response = await fetch(url);
       
        return await response.json();
    }

    
    async iniciarSesion(usuario, password) {
        let url = Origen + "/backend/controller/sesionController.php?request=iniciarSesion";
        let formData = new FormData();
        formData.append("usuario", usuario);
        formData.append("password", password);

        let config = {
            method: "POST",
            body: formData
        };

        let response = await fetch(url, config);
      
        let respuesta = await response.json();
        
        return respuesta;
    }

    async cambiarPassword(password){
        let url = Origen + "/backend/controller/sesionController.php?request=cambiarPassword";
        let formData = new FormData();
        formData.append("password", password);
        let config = {
            method: "POST",
            body: formData
        };
        let response = await fetch(url, config);
        return await response.json();
    }

    async cambiarTelefono(telefono){
        let url = Origen + "/backend/controller/sesionController.php?request=cambiarTelefono";
        let formData = new FormData();
        formData.append("telefono", telefono);
        let config = {
            method: "POST",
            body: formData
        };
        let response = await fetch(url, config);
        return await response.json();
    }

    async obtenerTelefono(){
        let url = Origen + "/backend/controller/sesionController.php?request=obtenerTelefono";
        let response = await fetch(url);
        return await response.json();
    }
}


export default SesionDAO;